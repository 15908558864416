@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    body {
      @apply font-barlow; /* Apply the Barlow font globally */
     
        overflow-x: hidden;
    
    }
  }
@import "@fortawesome/fontawesome-free/css/all.min.css";

